
import { defineComponent } from 'vue';
import {getStatistics} from '@/components/orders/orders.api';
import moment from 'moment';

export default defineComponent({
  name: 'StatisticsView',
  async mounted() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
    const response = await getStatistics(startOfMonth, endOfMonth);
    this.isLoading = false;
    if (response.ans) {
      this.total = response.total || 0;
      this.totalMonth = response.total_month || 0;
    }
  },
  data() {
    return {
      isLoading: true as boolean,
      total: 0 as number,
      totalMonth: 0 as number
    }
  }
});
