
import {defineComponent} from 'vue';
import { createOrder } from '@/components/orders/orders.api';
import Swal from 'sweetalert2';

export default defineComponent({
  name: 'HomeView',
  methods: {
    async submit() {
      if (!this.sum) return;

      this.isLoading = true;
      const response = await createOrder(this.sum);
      this.isLoading = false;

      if (!response.ans) {
        Swal.fire({
          title: 'Ошибка!',
          text: 'Попробуйте повторить запрос',
          icon: 'error'
        })
        return;
      }

      //redirect to status page
      const uid = response.data.uid;
      this.$router.push({
        path: '/status-'+uid
      });

    },
    resetNum() {
      this.sum = '';
    },
    numberWithSpaces(num:any) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    addNum(num:number):void {
      if (this.sum.length === 6) return;
      if (this.sum === '' && num === 0) return;
      this.sum += num;
    },
    getSum():string {
      return this.sum;
    },
    getSumFormat():string {
      return this.numberWithSpaces(this.getSum()) || '0';
    },
    eraseNum() {
      if (!this.sum.length) return;
      this.sum = this.sum.substr(0, this.sum.length - 1);
    }
  },
  data() {
    return {
      sum: '' as string,
      isLoading: false as boolean
    }
  }
});
