
import { defineComponent } from 'vue';
import {getOrderByUid, orderCheckStatus} from '@/components/orders/orders.api';


export default defineComponent({
  name: 'StatusView',
  methods: {
    numberWithSpaces(num:any) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSumFormat():string {
      return this.numberWithSpaces(this.getSum()) || '0';
    },
    getSum() {
      return this.sum;
    },
    async sleepTimeSec(sec: number) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, sec * 1000);
      });
    },
    async runIntervalStatusChecker() {
      while (this.intervalId) {
        //1. Делаем запрос на проверку статуса
        const response:any = await orderCheckStatus(this.model.id, this.model.order_id);

        if (response.ans) {
          const data = response.data;
          const status = data.order_state;
          switch (status) {
            case "PAID":
              this.intervalId = false;
              this.status = 'success';
              break;
            case "CREATED":
              break;
            default:
              this.intervalId = false;
              this.status = 'error';
              break;
          }
        }
        await this.sleepTimeSec(5);
      }
    }
  },
  unmounted() {
    this.intervalId = false;
  },
  async mounted() {
    const id: string = (this.$route.params.id).toString();
    this.id = id;
    const response = await getOrderByUid(id);
    this.model = response.data;
    const orderState = response.data.order_state;
    this.isLoading = false;

    switch(orderState) {
      case 'CREATED':
        this.status = 'process';
        this.runIntervalStatusChecker();
        break;
      case 'PAID':
        this.status = 'success';
        break;
      default:
        this.status = 'error';
        break;
    }
  },
  data() {
    return {
      id: '' as string,
      sum: '' as string,
      status: 'error' as string,
      isLoading: true as boolean,
      model: {} as any,
      intervalId: true as boolean
    }
  }
});
