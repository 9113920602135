import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { UiSpinner } from "@/components/ui";
import VueQrcode from '@chenfengyuan/vue-qrcode';

const app = createApp(App);
app.component('UiSpinner', UiSpinner);
app.component(VueQrcode.name, VueQrcode);
app.use(router).mount('#app');
