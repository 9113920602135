
import {defineComponent} from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'CommonHeader',
  components: {},
  methods: {
    updateTime() {
      this.datetime = moment().format('DD/MM/YYYY HH:mm');
    }
  },
  mounted() {
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 60 * 1000);

    window.addEventListener('online', () => {
      this.isOnline = true;
    });
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
  },
  data() {
    return {
      datetime: '' as string,
      isOnline: true as boolean
    }
  }
});
