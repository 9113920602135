import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatisticsView from '../views/StatisticsView.vue'
import HistoryView from '../views/HistoryView.vue'
import CatalogView from '../views/CatalogView.vue'
import StatusView from '../views/StatusView.vue'
import AboutView from '../views/AboutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/statistics',
    name: 'statistics',
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: StatisticsView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/status-:id',
    name: 'status',
    component: StatusView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
