import axios from 'axios';
import config from '@/config';

const axiosClient = axios.create({
    baseURL: config.apiUrl,
    params: {
        debug: true,
        token: config.token
    }
});

//createOrder, getOrderByUid, resetOrderStatus, orderCheckStatus, getOrderStatistics, getOrders

export async function createOrder(sum: string) {
    try {
        const formData = new FormData();
        formData.append('order_sum', sum);
        formData.append('comment', 'Оплата ' + sum);
        const {data} = await axiosClient.post('', formData, {
            params: {
                method: 'create'
            }
        });
        return {
            ans: true,
            data: data
        };
    } catch (error) {
        return {
            ans: false,
            error: error
        };
    }
}

export async function getOrderByUid(uid: string) {
    try {
        const {data} = await axiosClient.get('', {
            params: {
                uid: uid,
                method: 'getByUid'
            }
        });
        return data;
    } catch (error) {
        return {
            ans: false,
            error: error
        };
    }
}

export async function orderCheckStatus(id: string, orderId: string) {
    try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('order_id', orderId);
        const {data} = await axiosClient.post('', formData, {
            params: {
                method: 'status'
            }
        });
        return {
            ans: true,
            data: data
        };
    } catch (error) {
        return {
            ans: false,
            error: error
        };
    }
}

export async function getStatistics(dateStart: string, dateEnd: string) {
    try {
        const {data} = await axiosClient.get('', {
            params: {
                date_start: dateStart,
                date_end: dateEnd,
                method: 'getStatistics'
            }
        });
        return data;
    } catch (error) {
        return {
            ans: false,
            error: error
        };
    }
}

export async function getOrders(page: number, count: number) {
    try {
        const {data} = await axiosClient.get('', {
            params: {
                page: page,
                count: count,
                method: 'get'
            }
        });
        return data;
    } catch (error) {
        return {
            ans: false,
            error: error
        };
    }
}
