
import {defineComponent} from 'vue';
import {getOrders} from "@/components/orders/orders.api";
import moment from 'moment';

export default defineComponent({
  name: 'HistoryView',
  mounted() {
    this.loadMore();
  },
  methods: {
    getStatusCode(status:string) {
      switch (status) {
        case "PAID":
          return 'success';
        case "CREATED":
          return "process";
        default:
          return "error";
      }
    },
    goToStatusPage(uid:string): void {
      this.$router.push({
        path: '/status-'+uid
      });
    },
    async loadMore() {
      this.page++;
      const response:any = await getOrders(this.page, this.count);
      if (response.ans) {
        this.total = response.total
        for(let item of response.data) {
          this.orders.push({
            id: item.id,
            sum: item.sum,
            uid: item.uid,
            datetime: moment(item.datetime).format('DD/MM/YYYY HH:mm') || '',
            status: this.getStatusCode(item.order_state)
          } as any);
        }
      }
      this.isLoading = false;
    }
  },
  data() {
    return {
      isLoading: true,
      total: 0,
      page: 0,
      count: 10,
      orders: [] as any
    }
  }
});
